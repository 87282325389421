import { BrowserRouter } from 'react-router-dom';
import 'react-table-6/react-table.css';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import Store from './reducers';
import Router from './routes';

// ----------------------------------------------------------------------

export default function App() {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
    });

    return function cleanup() {
      login();
    };
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Provider store={Store}>
            <Router auth={auth} />
          </Provider >
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
