import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
// hooks
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { red } from '@mui/material/colors';
import { connect } from 'react-redux';
import { login } from '../services/auth';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const LoginPage = props => {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [remember, setRemember] = useState(false);
  const email = useRef(null);
  const password = useRef(null);

  useEffect(() => {
    if (props.auth) {
      navigate('/');
    }
  }, [props.auth, navigate]);

  const submit = async (event) => {
    event.preventDefault();

    if (!email.current.value || !password.current.value) {
      return errorHandler(0);
    }

    try {
      const loginResult = await login(email.current.value, password.current.value, remember);

      if (loginResult.status !== 200) {
        return errorHandler(loginResult.status);
      }
    } catch (e) {
      errorHandler(e.status);
    }
  };

  const errorHandler = (status) => {
    let text = '';
    switch (status) {
      case 0:
        text = 'Please, enter your credentials';
        break;
      case 1:
        text = 'Please, complete the Captcha Challenge';
        break;
      case 401:
        text = 'Wrong Credentials.';
        break;
      case 403:
        text = 'Access disabled. Contact you account manager.';
        break;
      case 404:
        text = 'Wrong Credentials.';
        break;
      default:
        text = 'There is a problem. Please, try again later.';
        break;
    }
    setError(text);
  };

  return (
    <>
      <Helmet><title> Login | EPS </title></Helmet>
      <StyledRoot>
        <Logo sx={{ position: 'fixed', top: { xs: 16, sm: 24, md: 40 }, left: { xs: 16, sm: 24, md: 40 }, }} />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>Welcome E-commerce Price Scraping</Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>Sign in</Typography>
            <Stack spacing={3}>
              <TextField name="email" label="Email address" inputRef={email}/>

              <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                inputRef={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <FormControlLabel control={<Checkbox name="remember" onClick={() => setRemember(r => !r)} />} label="Remember me" />
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={submit}>
              Login
            </LoadingButton>
            <LoadingButton color="warning" sx={{marginTop: '30px'}} fullWidth size="large" type="submit" variant="contained" onClick={()=> navigate('/register')}>
              Create account
            </LoadingButton>
            {error && <Typography sx={{ color: red[500], mt: 2 }}>{error}</Typography>}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

const mapStateToProps = state => ({
  auth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(LoginPage);
