import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
// hooks
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { red } from '@mui/material/colors';
import { signup } from '../services/auth';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const SignUp = () => {
    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const username = useRef(null);
    const email = useRef(null);
    const password = useRef(null);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const submit = async (event) => {
        event.preventDefault();

        if (!email.current.value || !password.current.value || !username.current.value) {
            return errorHandler(0);
        }

        if (!emailRegex.test(email.current.value)) {
            return errorHandler(1);
        }

        try {
            const created = await signup(email.current.value, password.current.value, username.current.value);

            if (created.status !== 201) {
                return errorHandler(created.status);
            }
            navigate('/dashboard/app')
        } catch (e) {
            console.log('Error Thrown', e);
            errorHandler(e.status);
        }
    };

    const errorHandler = (status) => {
        let text = '';
        switch (status) {
            case 0:
                text = 'Please, complete all the fields';
                break;
            case 1:
                text = 'The email format es wrong';
                break;
            case 401:
                text = 'Wrong Credentials.';
                break;
            case 403:
                text = 'Wrong Credentials.';
                break;
            default:
                text = 'There is a problem. Please, try again later.';
                break;
        }
        setError(text);
    };

    return (
        <>
            <Helmet><title> Login | EPS </title></Helmet>
            <StyledRoot>
                <Logo sx={{ position: 'fixed', top: { xs: 16, sm: 24, md: 40 }, left: { xs: 16, sm: 24, md: 40 }, }} />

                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>Welcome E-commerce Price Scraping</Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>Create account</Typography>
                        <Stack spacing={3}>
                            <TextField name="username" label="User name" inputRef={username} />
                            <TextField name="email" label="Email address" inputRef={email} />

                            <TextField name="password" label="Password" type={showPassword ? 'text' : 'password'} inputRef={password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={submit}>
                                Submit
                            </LoadingButton>
                            {error && <Typography sx={{ color: red[500] }}>{error}</Typography>}
                        </Stack>

                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}


export default SignUp;
