import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Alert,
    Button,
    Card,
    Container,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import { createURL } from 'src/services/url';
import { getAllNames as getCategories } from 'src/services/category';
import { getAllNames as getBrands } from 'src/services/brand';
import { useNavigate } from 'react-router-dom';

const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
// ----------------------------------------------------------------------

export default function URLCreate() {
    const [URL, setURL] = useState({ url: '', category_id: 0, brand_id: 0 });
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const dataC = await getCategories()
        const dataB = await getBrands()
        setCategories(dataC.array || [])
        setBrands(dataB.array || [])
    }

    const submit = async () => {
        if (urlPattern.test(URL.url)) {
            setError('')
            const data = await createURL(URL)
            setSuccess(data.success)
        } else {
            setError('URL pattern not valid')
            setSuccess(false)
        }
        setOpen(true)
    }

    const handleClose = () => {
        if (success) { navigate('/dashboard/url') }
        setOpen(false)
    }

    const handleChange = (e) => { setURL({ ...URL, [e.target.name]: e.target.value }) }

    return (
        <>
            <Helmet>
                <title> URL | EPS </title>
            </Helmet>
            <Container>
                <Typography variant="h4" gutterBottom>Create URL</Typography>
                <Card sx={{ padding: '30px' }}>
                    <TextField fullWidth id="standard-basic" name='url' label="URL" value={URL.url} onChange={handleChange} variant="standard" focused />
                    <InputLabel sx={{marginTop: '20px'}} id="category-select">Category</InputLabel>
                    <Select fullWidth labelId="category-select" name='category_id' value={URL?.category_id} label="Category" onChange={handleChange} >
                        {categories?.length ? categories.map(c => <MenuItem key={`cat-${c.id}`} value={c.id}>{c.name}</MenuItem>) : <MenuItem value={0}>{ }</MenuItem>}
                    </Select>
                    <InputLabel sx={{marginTop: '20px'}} id="brand-select">Brand</InputLabel>
                    <Select fullWidth labelId="brand-select" name='brand_id' value={URL?.brand_id} label="Brand" onChange={handleChange} >
                        {brands?.length ? brands.map(c => <MenuItem key={`cat-${c.id}`} value={c.id}>{c.name}</MenuItem>) : <MenuItem value={0}>{ }</MenuItem>}
                    </Select>
                    <Button sx={{ display: 'block', margin: '20px 0 20px 0' }} variant="contained" size="large" onClick={submit}>Save changes</Button>
                </Card>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={success ? "success" : 'error'} sx={{ width: '100%' }}>
                        {success ? "Edited successfully" : `Error trying to edit. ${error}`}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
