import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import Urls from './pages/URLs';
import URLCreate from './pages/URLs.new';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
// import PricesPage from './pages/PricesPage';
import Categories from './pages/Categories';
import Brands from './pages/Brands';
import BrandsCreate from './pages/Brands.new';
import CategoryCreate from './pages/Categories.new';
import URLEdit from './pages/URLs.edit';
import CategoryEdit from './pages/Categories.edit';
import BrandsEdit from './pages/Brands.edit';
import SignUp from './pages/SignUp';

// ----------------------------------------------------------------------

export default function Router({ auth }) {
  const loged = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'url', element: <Urls /> },
        { path: 'url-create', element: <URLCreate /> },
        { path: 'url-edit/:id', element: <URLEdit /> },
        // { path: 'prices', element: <PricesPage /> },
        { path: 'categories', element: <Categories /> },
        { path: 'category-edit/:id', element: <CategoryEdit /> },
        { path: 'category-create', element: <CategoryCreate /> },
        { path: 'brands', element: <Brands /> },
        { path: 'brand-edit/:id', element: <BrandsEdit /> },
        { path: 'brand-create', element: <BrandsCreate /> },
        // { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const unregistered = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <SignUp />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return auth ? loged : unregistered;
}
