import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import Store from '../reducers';

export const login = async (email, password, remember = false) => {
    const data = { email, password, remember };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, data);

        const { token } = res.data;
        if (token) {
            const payload = res.data.result;
            const action = {
                type: "LOGIN",
                token,
                payload
            };

            Store.dispatch(action);
            setAuthorizationToken(token);
        }

        return res.data;
    } catch (err) {
        return err.response.data;
    }
};

export const signup = async (email, password, username) => {
    const data = { email, password, username };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/signup`, data);

        const { token } = res.data;
        if (token) {
            const payload = res.data.result;
            const action = {
                type: "LOGIN",
                token,
                payload
            };

            Store.dispatch(action);
            setAuthorizationToken(token);
        }

        return {status: res.status, ...res.data};
    } catch (err) {
        return {status: err.response.status};
    }
};

export function logout() {
    return dispatch => {
        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY);
        setAuthorizationToken(false);
        dispatch();
    };
}