import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
    Alert,
    Button,
    Card,
    Container,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { createBrand } from 'src/services/brand';
import { useNavigate } from 'react-router-dom';
// components

// ----------------------------------------------------------------------

export default function BrandsCreate() {
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const submit = async () => {
        const data = await createBrand(name)
        setError(data.error)
        setSuccess(data.success)
        setOpen(true)
    }

    const handleClose = () => {
        if (success) { navigate('/dashboard/brands') }
        setOpen(false)
    }

    return (
        <>
            <Helmet>
                <title> Brand | EPS </title>
            </Helmet>

            <Container  >
                <Typography variant="h4" gutterBottom>
                    Creating a new brand
                </Typography>
                <Card sx={{ padding: '30px' }}>
                    <TextField fullWidth id="standard-basic" label="Brand name" value={name} onChange={e => setName(e.target.value)} variant="standard" focused />
                    <Button sx={{ display: 'block', margin: '20px 0 20px 0' }} variant="contained" size="large" onClick={submit}>Edit</Button>
                </Card>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} onClose={handleClose}>
                    <Alert onClose={handleClose} variant="filled" severity={success ? "success" : 'error'} sx={{ width: '100%' }}>
                        {success ? "Brand created successfully" : `Brand not created. ${error}`}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
