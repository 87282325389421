import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Alert,
    Button,
    Card,
    Container,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import { editBrand, getByID } from 'src/services/brand';
import { useNavigate, useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function BrandsEdit() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const data = await getByID(id)
            setName(data.name)
        }
        fetchData()
    }, [id])

    const submit = async () => {
        const data = await editBrand(id, name)
        setError(data.error)
        setSuccess(data.success)
        setOpen(true)
    }

    const handleClose = () => {
        if (success) { navigate('/dashboard/brands') }
        setOpen(false)
    }

    return (
        <>
            <Helmet><title> Brands | EPS </title></Helmet>
            <Container  >
                <Typography variant="h4" gutterBottom>
                    Edit brand
                </Typography>
                <Card sx={{ padding: '30px' }}>
                    <TextField fullWidth id="standard-basic" label="Brand name" value={name} onChange={e => setName(e.target.value)} variant="standard" focused />
                    <Button sx={{ display: 'block', margin: '20px 0 20px 0' }} variant="contained" size="large" onClick={submit}>Edit</Button>
                </Card>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} onClose={handleClose}>
                    <Alert onClose={handleClose} variant="filled" severity={success ? "success" : 'error'} sx={{ width: '100%' }}>
                        {success ? "Brand edited successfully" : `Brand not edited. ${error}`}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
