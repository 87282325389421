import axios from "axios";

const { CancelToken } = axios;
export let cancel;

export const getAll = async (options) => {
    const { sort, direction, filters, status, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
        status
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/urls?results=${results}&offset=${offset}`, body, {
            cancelToken: new CancelToken((c) => { cancel = c; })
        });

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const getByID = async (id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/urls/${id}`);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const erase = async (id) => {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/urls/${id}`,);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const createURL = async (data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/urls/create`, data);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const editURL = async (id, data) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/urls/edit/${id}`, data);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}