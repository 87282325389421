import axios from "axios";

const { CancelToken } = axios;
export let cancel;

export const getAll = async (options) => {
    const { sort, direction, filters, status, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
        status
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/brands?results=${results}&offset=${offset}`, body, {
            cancelToken: new CancelToken((c) => { cancel = c; })
        });

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const createBrand = async (name) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/brands/create`, { name });

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const editBrand = async (id, name) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/brands/edit/${id}`, { name });

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const getByID = async (id) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/brands/${id}`);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}

export const getAllNames = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/brands/all_names`);

        return res.data;
    } catch (err) {
        return err.response.data;
    }
}