import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { connect } from 'react-redux';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Page404 = props => (
  <>
    <Helmet><title> 404 Page Not Found | Minimal UI </title></Helmet>

    <Container>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>Sorry, page not found!</Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
          spelling.
        </Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_404.svg"
          sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to={props.auth ? "/" : '/login'} size="large" variant="contained" component={RouterLink}>
          Go to {props.auth ? 'Home' : 'Login'}
        </Button>
      </StyledContent>
    </Container>
  </>
)

const mapStateToProps = state => ({
  auth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Page404);