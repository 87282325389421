import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Stack,
    Button,
    Container,
    Typography,
} from '@mui/material';
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
// components
import { useNavigate } from 'react-router-dom';
import ReactTable from 'react-table-6';
import { cancel, getAll } from '../services/category';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

export default function Categories() {
    const navigate = useNavigate()
    const [pages, setPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchData()
    }, []);

    const columns = [
        {
            accessor: 'id',
            Header: 'ID',
        },
        {
            accessor: 'name',
            Header: 'Name',
        },
        {
            accessor: 'id',
            filterable: false,
            Cell: property => (
                <Dropdown>
                    <TriggerButton>Actions</TriggerButton>
                    <Menu slots={{ listbox: StyledListbox }}>
                        <StyledMenuItem onClick={() => navigate(`/dashboard/category-edit/${property.value}`)}>
                            Edit
                        </StyledMenuItem>
                        {/* <StyledMenuItem onClick={() => deleteURL(property.value)}>
                            Delete
                        </StyledMenuItem> */}
                    </Menu>
                </Dropdown>
            ),
        },
    ];

    const fetchData = async (state) => {
        setLoading(true);
        let options = {};

        if (state) {
            options = {
                sort: state.sorted.length > 0 ? state.sorted[0].id : 'id',
                direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
                filters: state.filtered.length > 0 ? state.filtered : [],
                results: state.pageSize,
                offset: (state.page + 1) * state.pageSize - state.pageSize
            };
        } else {
            options = {
                sort: 'id',
                direction: 'desc',
                filters: [],
                results: 10,
                offset: 0
            };
        }

        const data = await getAll(options);
        setPages(Math.ceil(data.count / (state?.pageSize || 10)))
        setCategories(data.array || []);
        setLoading(false);
    }
    // const deleteURL = async () => {
        // await erase(targetId);
        // setOpen(null)
        // await fetchData();
    // }

    return (
        <>
            <Helmet>
                <title> Categories | EPS </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Categories
                    </Typography>
                    <Button variant="contained" onClick={() => navigate('/dashboard/category-create')} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Category
                    </Button>
                </Stack>
                <ReactTable
                    data={categories}
                    columns={columns}
                    loading={loading}
                    pages={pages}
                    showPagination
                    showPaginationTop={false}
                    showPaginationBottom
                    showPageJump
                    defaultPageSize={10}
                    filterable
                    onFetchData={fetchData}
                    onSortedChange={cancel}
                    onFilteredChange={cancel}
                    className="-striped -highlight"
                    manual
                />
            </Container>
        </>
    );
}

const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledListbox = styled('ul')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    z-index: 1;
    `,
);

const StyledMenuItem = styled(MenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    }
    `,
);

const TriggerButton = styled(MenuButton)(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    box-sizing: border-box;
    min-height: calc(1.5em + 22px);
    border-radius: 12px;
    padding: 8px 14px;
    line-height: 1.5;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
  
    &:focus-visible {
      border-color: ${blue[400]};
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
    `,
);
